import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//Media
import play from '../img/play.svg';


const VideoNavigator = (props) => {

    const { t } = useTranslation('common');

    if (props.finale) return null;
    else
        return (
            <>
                <div className="box col-12 videoNavigator">
                    <div className="container justify-center">
                        <div className="box col-4">
                            <Link to="/finale" className="button navigator">
                                <div className="info">
                                    {t('button-finale')}
                                </div>
                                <div className="icon">
                                    <img src={play} alt="Complete Experience" />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        );
}

export default VideoNavigator;