import React, { useEffect } from "react";
import VideoPage from "../components/VideoPage";
import eloquaHelper from '../helpers/eloquaHelper';
const Finale = () => {
  useEffect(() => {
    eloquaHelper.tryToTrack();
  });

  return <VideoPage videoName="finale" isFinale />;
};

export default Finale;
