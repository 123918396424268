import React, { useEffect } from "react";
import VideoPage from "../components/VideoPage";
import eloquaHelper from "../helpers/eloquaHelper";

const Speech2 = () => {
  useEffect(() => {
    eloquaHelper.tryToTrack();
  });

  return <VideoPage videoName="speech2" />;
};

export default Speech2;
