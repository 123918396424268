import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//Media
import play from "../img/play.svg";

const VideoSelector = (props) => {
  const { t } = useTranslation("common");

  return (
    <>
      <div className="videoSelectorWrapper container">
        {props.finale ? (
          <>
            <div className="box col-12 buttonsInfo center">
              {t("message-thankyou")}
            </div>
            <div className="videoSelector box col-8 justify-between">
              <div className="container">
                {/* <div className="box col-4">
                            <Link to="/" className="button selector finale">
                                <div className="info">
                                    <div className="title">{t('button-watch-again')}</div>

                                </div>
                            </Link>
                        </div> */}
                <div className="box col-6">
                  <a
                    href={t("link-discover")}
                    className="button selector finale"
                    target="_blank"
                  >
                    <div className="info">
                      <div className="title">{t("button-discover")}</div>
                    </div>
                  </a>
                </div>
                <div className="box col-6">
                  <a
                    href={t("link-enquire")}
                    className="button selector finale"
                    target="_blank"
                  >
                    <div className="info">
                      <div className="title">{t("button-enquire")}</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </>
        ) : (
            <>
              <div className="box col-12 buttonsInfo center">
                {t("message-epxerience")}
              </div>
              <div className="videoSelector box col-12 justify-between">
                <div className="container">
                  <div className="box col-4">
                    <Link
                      to="/speech1"
                      className="button selector"
                    >
                      <div className="info">
                        <div className="title">{t("button-video-1-row-1")}</div>
                        <div className="author">{t("button-video-1-author")}</div>
                      </div>
                      <div className="icon">
                        {/* {viewedVideos.speech1 ? (<img src={check} alt="Viewed" />) : (<img src={play} alt="Not Viewed" />)} */}
                        <img src={play} alt="Not Viewed" />
                      </div>
                    </Link>
                  </div>
                  <div className="box col-4">
                    <Link
                      to="/speech2"
                      className="button selector"
                    >
                      <div className="info">
                        <div className="title">{t("button-video-2-row-1")}</div>
                        <div className="author">{t("button-video-2-author")}</div>
                      </div>
                      <div className="icon">
                        {/* {viewedVideos.speech2 ? (<img src={check} alt="Viewed" />) : (<img src={play} alt="Not Viewed" />)} */}
                        <img src={play} alt="Not Viewed" />
                      </div>
                    </Link>
                  </div>
                  <div className="box col-4">
                    <Link
                      to="/speech3"
                      className="button selector"
                    >
                      <div className="info">
                        <div className="title">{t("button-video-3-row-1")}</div>
                        <div className="author">{t("button-video-3-author")}</div>
                      </div>
                      <div className="icon">
                        {/* {viewedVideos.speech3 ? (<img src={check} alt="Viewed" />) : (<img src={play} alt="Not Viewed" />)} */}
                        <img src={play} alt="Not Viewed" />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
      </div>
    </>
  );
};

export default VideoSelector;
