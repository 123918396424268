import React, { useEffect } from "react";
import VideoPage from "../components/VideoPage";
import eloquaHelper from "../helpers/eloquaHelper";

const Reveal = () => {
  useEffect(() => {
    eloquaHelper.tryToTrack();
    // console.log('SONO REVEAL ***');
  });

  return <VideoPage videoName="reveal" isReveal />;
};

export default Reveal;
