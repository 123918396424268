import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import TagManager from 'react-gtm-module'

// I18n Translations
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";
import common_es from "./translations/es/common.json";
import common_fr from "./translations/fr/common.json";
import common_it from "./translations/it/common.json";
import common_ja from "./translations/ja/common.json";
import common_ko from "./translations/ko/common.json";
import common_zh from "./translations/zh/common.json";


i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },  // React already does escaping
  defaultValue: 'en', //default
  load: 'languageOnly',
  fallbackLng: 'en', //default
  resources: {
    de: {
      common: common_de
    },
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    es: {
      common: common_es
    },
    fr: {
      common: common_fr
    },
    it: {
      common: common_it
    },
    ja: {
      common: common_ja
    },
    ko: {
      common: common_ko
    },
    zh: {
      common: common_zh
    }
  },
});

const tagManagerArgs = {
  gtmId: 'GTM-KLDD6J'
}

TagManager.initialize(tagManagerArgs)


ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

