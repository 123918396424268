import React, { Suspense, useState, useEffect } from "react";

import { BrowserRouter as Router } from "react-router-dom";

import "react-dropdown/style.css";
import "./App.scss";
import "video.js/dist/video-js.css";

import Container from "./Container";
import Loader from "./components/Loader";
import storageHelper from "./helpers/storageHelper";
import NoIE from "./screens/NoIE";
import { isIE } from "react-device-detect";


const App = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const qs = new URLSearchParams(window.location.search);

    storageHelper.setToken(qs.get("t"));
    storageHelper.setViewed("speech1");
    storageHelper.setViewed("speech2");
    storageHelper.setViewed("speech3");
    setTimeout(() => setLoading(false), 500); //Loading Timeout
  }, []);
  if (isIE === true) {
    return <NoIE />;
  } else {
    return (
      <Router>
        {loading === false ? (
          <Suspense fallback="loading">
            <Container />
          </Suspense>
        ) : (
          <Loader />
        )}
      </Router>
    );
  }
};

export default App;
