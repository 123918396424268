class EloquaHelper {
  foundCookie = false;
  constructor() {
    // console.log("CONSTRUCTOR DI ELOQUAHELPER");
    this.checkCookie();
  }

  checkCookie() {
    if (!this.foundCookie) {
      const allCookies = document.cookie.split("; ");
      allCookies.forEach((element) => {
        if (element.startsWith("OptanonConsent")) {
          // console.log(element.match("C0004%3A1"));
          if (element.match("C0004%3A1") != null) {
            // console.log("FOUND CONSENT COOKIE");
            this.foundCookie = true;
            window.async_load(); //load Eloqua
          }
        }
      });
    }
    return this.foundCookie;
  }

  tryToTrack(event) {
    if (this.checkCookie()) {
      // console.log("USER IS OK WITH TRACKING " + event);
      window.onTrackNeeded(event);
    } else {
      // console.log("USER DOES NOT WANT TRACKING " + event);
    }
  }
}
export default new EloquaHelper();
