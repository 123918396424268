import React from 'react';
import { useTranslation } from "react-i18next";
import VideoBackground from '../components/VideoBackground';
import styled from "styled-components";

//Media
import silverLogo from '../img/chromeHorse.png';

const NoIE = () => {

    const { t } = useTranslation('common');

    return (
        <Wrapper>
            <section className="route-section">
                <div className="wrapper app-wrapper">
                    <div className="container full reveal-container">
                        <div className="box col-12 center container">
                            <div className="logo-container">
                                <img src={silverLogo} alt="Ferrari Logo" className="logo" height="80"/>
                            </div>
                        </div>
                        <div className="box col-12 center">
                            <h2 className="old-browser">{t('oldbrowser-message')}</h2>
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
}

const Wrapper = styled.div`


    section.route-section {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }      
`;

export default NoIE;
