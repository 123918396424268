import React from "react";
import videojs from "video.js";
import "videojs-landscape-fullscreen";
import "../styles/F173video.scss";
import storageHelper from "../helpers/storageHelper";
import eloquaHelper from "../helpers/eloquaHelper";

import { isIOS, isMobile } from "react-device-detect";
import { withTranslation } from "react-i18next";
class VideoPlayerSTD extends React.Component {

  constructor(props) {
    super(props);
    this.completion = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
  }

  percSampling(perc) {
    let reached = parseInt(perc, 10);
    for (let i = 1; i < 11; i++) {
      if (reached > i * 10 && !this.completion[i - 1]) {
        this.completion[i - 1] = true;
        let path = this.props.name + "/" + i * 10;
        eloquaHelper.tryToTrack(path);
      }
    }
  }

  componentDidUpdate(previousProps) {
    // console.log("componentDidUpdate");
    // console.log(this.props);
    const oldVideoPath = storageHelper.videoPathForLanguage(
      previousProps.lang,
      previousProps.name
    );
    const newVideoPath = storageHelper.videoPathForLanguage(
      this.props.lang,
      this.props.name
    );
    if (oldVideoPath !== newVideoPath) {
      this.player.src([
        {
          src: newVideoPath,
        },
      ]);
    }
  }
  componentDidMount() {
    // console.log("component did mount");
    // console.log(this.props);
    // console.log(this);
    const videoJsOptions = {
      autoplay: "any",
      controls: true,
      controlBar: {
        playToggle: true,
        progressControl: true,
        remainingTimeDisplay: true,
        pictureInPictureToggle: false,
      },
      muted: true,
      fill: false,
      fluid: true,
      sources: [
        {
          src: storageHelper.videoPathForLanguage(
            this.props.lang,
            this.props.name
          ),
          type: "video/mp4",
        },
      ],
    };

    this.player = videojs(this.videoNode, videoJsOptions);
    this.player.landscapeFullscreen({
      fullscreen: {
        enterOnRotate: true,
        alwaysInLandscapeMode: true,
        iOS: false,
      },
    });
    this.player.on("ended", () => {
      let path = this.props.name + "/100";
      // console.log("Eloqua path: " + path);
      eloquaHelper.tryToTrack(path);
      this.props.action("ended");
    });
    this.player.on("pause", () => {
      this.props.action("pause");
    });
    this.player.on("play", () => {
      let path = this.props.name + "/play";
      //console.log('Eloqua path: ' + path);
      eloquaHelper.tryToTrack(path);
      this.props.action("play");
    });
    this.player.on("timeupdate", () => {
      this.props.action("timeupdate");
      const percentage =
        (this.player.currentTime() * 100) / this.player.duration();
      this.percSampling(percentage.toFixed(0));
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856

  render() {
    return (
      <div data-vjs-player className="videoJsInnerContainer">
        <video
          ref={(node) => (this.videoNode = node)}
          className="video-js vjs-default-skin"
          playsInline
          autoPlay
        />
      </div>
    );
  }
}

export default withTranslation()(VideoPlayerSTD);
