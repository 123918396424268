import React, { useState, useEffect } from 'react';

const getWidth = () => window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

function useCurrentWitdh() {
    // save current window width in the state object
    let [width, setWidth] = useState(getWidth());

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {
        // timeoutId for debounce mechanism
        let timeoutId = null;
        const resizeListener = () => {
            // prevent execution of previous setTimeout
            clearTimeout(timeoutId);
            // change width from the state object after 150 milliseconds
            timeoutId = setTimeout(() => { setWidth(getWidth()); console.log('updated') }, 150);
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        }
    }, [])

    return width;
}


const VideoBackground = (props) => {

    const { breakpoint } = props;
    let innerBreakpoint = breakpoint ?? 681;
    let width = useCurrentWitdh();

 

    if (width > innerBreakpoint) {
        return (
            <video playsInline autoPlay={true} muted loop id="bgvid" className="video-background">
                <source src="/background.mp4" type="video/mp4" />
            </video>
        );
    } else {
        return (
            <video playsInline autoPlay={true} muted loop id="bgvid" className="video-background">
                <source src="/background-mobile.mp4" type="video/mp4" />
            </video>
        );
    }

}

export default VideoBackground;