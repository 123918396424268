import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../components/LanguageSwitcher";
import VideoBackground from "../components/VideoBackground";
import VideoPageSTD from "../components/VideoPageSTD";
import { Link, useRouteMatch } from "react-router-dom";
import eloquaHelper from "../helpers/eloquaHelper";

//Media
import silverLogo from "../img/chromeHorse.png";
import audio_icon from "../img/8d_icon.png";

let revealDay = true;

const Home = ({ match }) => {
  const { t } = useTranslation("common");
  const { url, path } = useRouteMatch();

  useEffect(() => {
    // console.log('URL ');
    // console.log(url);
    // console.log(path);
    eloquaHelper.tryToTrack();
  });

  if (revealDay)
    return (
      <div className="wrapper app-wrapper">
        <div className="container full reveal-container">
          <VideoBackground />
          <div className="container header-home justify-between">
            <div className="box col-3 header-left"></div>
            <div className="box col-6 header-center">
              <div>
                <img
                  src={silverLogo}
                  alt="Ferrari Logo"
                  className="logo"
                  height="80"
                />
              </div>
            </div>
            <div className="box col-3 visible right language-switcher header-right ">
              <LanguageSwitcher home />
            </div>
          </div>

          <div className="box col-12 center">
            <h1 className="main-title">BEYOND IMAGINATION</h1>
            <h2 className="sub-title">
              {t("intro-title-1")}
              <br className="breaker" />
              {t("intro-title-2")}
            </h2>
            <div className="container">
              <div className="box col-12 audio-info">
                <img
                  src={audio_icon}
                  alt="8D Compatible "
                  className="audio_icon"
                />
                {t("audio-8d-info")}
              </div>
              <div className="box col-4 start-button">
                <Link to="/reveal" className="button play">
                  {t("play-button")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else return <VideoPageSTD videoName="savethedate" isSTD />;
};

export default Home;
