import React from 'react';
import logo from '../img/logo.svg';

const Loader = () => {
    return (
        <div className="container loader-logo-container column">
            <img src={logo} alt="Ferrari Logo" className="loader-logo" />
        </div>
    )
}

export default Loader;