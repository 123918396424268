import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "react-dropdown";

const LanguageSwitcher = (props) => {
  const { i18n } = useTranslation("common");
  const handleLanguageChange = (selected) => {
    // console.log(selected);
    // console.log(selectedLanguage);
    i18n.changeLanguage(selected.value.toLowerCase());
    setSelectedLanguage(selected);
  };

  const languageOptions = [
    { value: "de", label: "DEU" },
    { value: "en", label: "ENG" },
    { value: "es", label: "ESP" },
    { value: "fr", label: "FRA" },
    { value: "it", label: "ITA" },
    { value: "ja", label: "日本語" },
    { value: "ko", label: "한국어" },
    { value: "zh", label: "中文" },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(item => item.value===i18n.language.substr(0,2)) || languageOptions[1]);

  //let classNameDrop = props.bottom ? 'language-selector-wrapper bottom' : 'language-selector-wrapper';
  let classNameDrop = props.home
    ? "language-selector-wrapper home"
    : "language-selector-wrapper";

  return (
    <Dropdown
      //baseClassName="F173RHT"
      className={classNameDrop}
      controlClassName="language-selector"
      arrowClassName="language-selector-arrow"
      menuClassName="language-items"
      options={languageOptions}
      onChange={handleLanguageChange}
      value={selectedLanguage}
    //   placeholder="Lang"
    />
  );
};

export default LanguageSwitcher;
