import videostructure from "../videostructure.json";

class StorageHelper {
  constructor() {
    // console.log("CONSTRUCTOR DI STORAGEHELPER " + this.viewedVideos);
    console.log(videostructure["en"]["std"]);
  }

  viewedVideos() {
    return JSON.parse(localStorage.getItem("jntVideos")) || [];
  }

  isViewed(name) {
    return this.viewedVideos().indexOf(name.toLowerCase()) >= 0;
  }

  setViewed(name) {
    name = name.toLowerCase();

    if (!this.isViewed(name)) {
      var vv = this.viewedVideos();
      vv.push(name);
      localStorage.setItem("jntVideos", JSON.stringify(vv));
    }
  }

  showProgress(name) {
    return this.isViewed(name);
  }

  setToken(tok) {
    if (tok != null) {
      localStorage.setItem("jntTok", tok);
    }
  }

  getToken() {
    return localStorage.getItem("jntTok");
  }

  isAuthorized() {
    return (
      this.getToken() === "409c77c0-0de7-4ea9-8008-05030b12e9c8" ||
      this.getToken() ===
        "409c77c0-0de7-4ea9-8008-05030b12e9c8409c77c0-0de7-4ea9-8008-05030b12e9c8"
    );
  }

  videoPathForLanguage(lang, video) {
    lang = lang.toLowerCase().substr(0, 2);
    console.log("video path for " + lang + " name " + video);
    const result = videostructure[lang.toLowerCase()][video.toLowerCase()][0];
    console.log(result);
    return result;
  }
  videoTypeForLanguage(lang, video) {
    lang = lang.toLowerCase().substr(0, 2);
    console.log("video type for " + lang + " name " + video);
    const result = videostructure[lang.toLowerCase()][video.toLowerCase()][1];
    console.log(result);
    return result;
  }
}
export default new StorageHelper();
