import React, { useState } from "react";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  withRouter,
} from "react-router-dom";
import storageHelper from "./helpers/storageHelper";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useTranslation } from "react-i18next";

import Home from "./screens/Home";
import Welcome from "./screens/Welcome";
import Token from "./screens/Token";
import Reveal from "./screens/Reveal";
import Speech1 from "./screens/Speech1";
import Speech2 from "./screens/Speech2";
import Speech3 from "./screens/Speech3";
import Finale from "./screens/Finale";
import SaveTheDate from "./screens/SaveTheDate";
import Page404 from "./screens/Page404";
import NoAuth from "./screens/NoAuth";

const Container = ({ location }) => {
  const { i18n } = useTranslation("common");
  const [authorized, setAuthorized] = useState(storageHelper.isAuthorized());

  return (
    <Wrapper>
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          timeout={{ enter: 300, exit: 300 }}
          classNames={"fade"}
        >
          <section className="route-section">
            <Switch>
              <Route exact path="/" component={authorized ? Home : NoAuth} />
              {/* <Route
                exact
                path="/savethedate"
                component={authorized ? SaveTheDate : NoAuth}
              /> */}
              <Route
                exact
                path="/welcome"
                component={authorized ? Welcome : NoAuth}
              />
              <Route
                exact
                path="/reveal"
                component={authorized ? Reveal : NoAuth}
              />
              <Route
                exact
                path="/speech1"
                component={authorized ? Speech1 : NoAuth}
              />
              <Route
                exact
                path="/speech2"
                component={authorized ? Speech2 : NoAuth}
              />
              <Route
                exact
                path="/speech3"
                component={authorized ? Speech3 : NoAuth}
              />
              <Route
                exact
                path="/finale"
                component={authorized ? Finale : NoAuth}
              />
              <Route exact path="/noauth" component={NoAuth} />
              <Route exact path="/:token" component={Token} />
              <Route exact path="*">
                <Page404 />
              </Route>
            </Switch>
          </section>
        </CSSTransition>
      </TransitionGroup>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .fade-enter {
    opacity: 0.01;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }

  div.transition-group {
    position: relative;
  }

  section.route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

export default withRouter(Container);
