import React, { useEffect } from "react";
import VideoPage from "../components/VideoPage";
import eloquaHelper from "../helpers/eloquaHelper";

const Speech3 = () => {
  useEffect(() => {
    eloquaHelper.tryToTrack();
  });

  return <VideoPage videoName="speech3" />;
};

export default Speech3;
