import React from "react";
import videojs from "video.js";
import "videojs-landscape-fullscreen";
import "../styles/F173video.scss";
import storageHelper from "../helpers/storageHelper";
import eloquaHelper from "../helpers/eloquaHelper";
import { isMobile, isAndroid } from "react-device-detect";

import { withTranslation } from "react-i18next";

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.completion = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
  }

  percSampling(perc) {
    let reached = parseInt(perc, 10);
    for (let i = 1; i < 11; i++) {
      if (reached > i * 10 && !this.completion[i - 1]) {
        this.completion[i - 1] = true;
        let path = this.props.name + "/" + i * 10;
        // console.log("Completed " + i * 10);
        // console.log("Eloqua path " + path);
        eloquaHelper.tryToTrack(path);
      }
    }
  }

  componentDidUpdate(previousProps) {
    // console.log("COMPONENT DID UPDATE");
    // console.log(this.props);
    // console.log(previousProps);
    const oldVideoPath = storageHelper.videoPathForLanguage(
      previousProps.lang,
      previousProps.name
    );
    const newVideoPath = storageHelper.videoPathForLanguage(
      this.props.lang,
      this.props.name
    );
    if (oldVideoPath !== newVideoPath) {
      this.player.src([
        {
          src: newVideoPath,
          type: storageHelper.videoTypeForLanguage(
            this.props.lang,
            this.props.name
          ),
        },
      ]);
    }
  }
  componentDidMount() {
    // console.log("component did mount");
    // console.log(this.props.name);

    // console.log(this.player);
    const videoJsOptions = {
      autoplay: "any",
      controls: true,
      controlBar: {
        playToggle: true,
        progressControl: storageHelper.showProgress(this.props.name),
        remainingTimeDisplay: true,
        pictureInPictureToggle: false,
        captions: false,
        subtitles: false,
      },
      html5: {
        vhs: {
          overrideNative: isAndroid
        },
        nativeAudioTracks: !isAndroid,
        nativeVideoTracks: !isAndroid
      },    
      fill: false,
      fluid: true,
      sources: [
        {
          src: storageHelper.videoPathForLanguage(
            this.props.lang,
            this.props.name
          ),
          type: storageHelper.videoTypeForLanguage(
            this.props.lang,
            this.props.name
          ),
        },
      ],
    };

    this.player = videojs(this.videoNode, videoJsOptions);
    // const newVideoPath = storageHelper.videoPathForLanguage(
    //   this.props.lang,
    //   this.props.name
    // );
    //   setTimeout(()=>{
    //   this.player.src([
    //     {
    //       src: newVideoPath,
    //       type: "video/mp4",
    //     },
    //   ]);
    // }, 600);

    this.player.landscapeFullscreen({
      fullscreen: {
        enterOnRotate: true,
        alwaysInLandscapeMode: true,
        iOS: false,
      },
    });
    this.player.on("ended", () => {
      // console.log("player on ended");
      let path = this.props.name + "/100";
      //console.log("Eloqua path: " + path);
      eloquaHelper.tryToTrack(path);
      this.props.action("ended");
      this.player.reset();
    });
    this.player.on("pause", () => {
      // console.log("player on pause");
      this.props.action("pause");
    });
    this.player.on("play", () => {
      // console.log("player on play");
      // console.log(this.player.paused());
      let path = this.props.name + "/play";
      if (isMobile) {
        this.player.requestFullscreen();
        if (isAndroid) {
          window.screen.lockOrientationUniversal("landscape");
        }
      }
      eloquaHelper.tryToTrack(path);
      this.props.action("play");
    });
    // this.player.on("ready", () => {
    //   console.log("player on ready");
    //   this.props.action("ready");
    // });
    // this.player.on("error", () => {
    //   console.log("player on error");
    // });
    this.player.on("timeupdate", () => {
      // this.props.action("timeupdate");
      const percentage =
        (this.player.currentTime() * 100) / this.player.duration();
      this.percSampling(percentage.toFixed(0));
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    // console.log("componentWillUnmount");
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856

  render() {
    return (
      <div data-vjs-player className="videoJsInnerContainer">
        <video
          ref={(node) => (this.videoNode = node)}
          className="video-js vjs-default-skin"
          playsInline
          autoPlay
        />
      </div>
    );
  }
}

export default withTranslation()(VideoPlayer);
