import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

//Media
import logo from '../img/logo.svg';

const Page404 = () => {
    const { t } = useTranslation('common');
useEffect(()=>{
    // console.log('USE EFFECT PAGINA ERRORE');
});
    return (
        <div className="wrapper app-wrapper">
            <div className="container full reveal-container">
                <div className="box col-12 center container">
                    <div className="logo-container">
                        <img src={logo} alt="Ferrari Logo" className="logo" />
                    </div>
                </div>
                <div className="box col-12 center">
                    <h1 className="main-title">404</h1>
                    <h2 className="sub-title">Page not found!</h2>
                    <div className="container">
                        <div className="box col-3">
                            <Link to="/" className="button">Go back home</Link>
                        </div>
                    </div>
                </div>

                {/* <video playsinline autoplay muted loop poster="/dev-background-min.png" id="bgvid"> */}
                {/* <video playsinline autoplay="true" muted loop poster="/dev-background-min.png" id="bgvid">
                    <source src="/video-background.mp4" type="video/mp4" />
                </video> */}
            </div>
        </div>
    );
}


export default Page404;
