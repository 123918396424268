import React from "react";
import { useTranslation } from "react-i18next";
import VideoBackground from "./VideoBackground";
import LanguageSwitcher from "../components/LanguageSwitcher";
//Media
import sf90logo from "../img/SF90_logo.png";

const Header = (props) => {
  const { t } = useTranslation("common");
  return (
    <>
      <VideoBackground />
      <div className="container header justify-between">
        <div className="box col-6 headline">
          <div>
            {props.showLogo ? (
              <img
                src={sf90logo}
                alt="Ferrari Logo"
                className="header-logo-alt"
              />
            ) : null}
          </div>
        </div>
        <div className="box col-3 visible right language-switcher ">
          <LanguageSwitcher />
        </div>
      </div>
    </>
  );
};

export default Header;
