import React, { useEffect } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import storageHelper from "../helpers/storageHelper";
let revealDay = false;

const Token = ({ match }) => {
  const { url, path } = useRouteMatch();
  useEffect(() => {
    // console.log(match.params.token);
    storageHelper.setToken(match.params.token);
  });
  if (revealDay) {
    return <Redirect to="/welcome" />;
  } else {
    return <Redirect to="/savethedate" />;
  }
};
export default Token;
