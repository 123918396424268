import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import VideoSelectorHorizontal from "../components/VideoSelectorHorizontal";
import VideoNavigator from "../components/VideoNavigator";
import VideoPlayer from "../components/VideoPlayer";
import storageHelper from "../helpers/storageHelper";
import { isMobile } from "react-device-detect";
//Media
import close_b from "../img/close_button.svg";
import restart from "../img/restart.svg";
import { useEffect } from "react";
const scrollToRefObject = (ref) =>
  window.scrollTo({ top: ref.current.offsetTop + 1, behavior: "smooth" });

const VideoPage = (props) => {
  const { videoName, isReveal, isFinale } = props;
  const { t, i18n } = useTranslation("common");
  const myRef = useRef(null);
  const executeScroll = () => scrollToRefObject(myRef);
  const [showRotate, setShowRotate] = useState(false);

  const [isEnd, setIsEnd] = useState(false);
  const [showLogo, setShowLogo] = useState(storageHelper.isViewed("reveal"));
  useEffect(() => {});

  const handleVideoAction = (action) => {
    // console.log("VideoPage.handleVideoAction " + action);
    switch (action) {
      case "ended":
        setIsEnd(true);
        storageHelper.setViewed(videoName);
        setShowLogo(true);
        if (isMobile) {
          executeScroll();
        }
        break;
      case "pause":
        break;
      case "ready":
        break;
      case "play":
        setShowRotate(true);
        setTimeout(() => {
          setShowRotate(false);
        }, 5000);
        break;
      default:
    }
  };

  return (
    <div className="wrapper app-wrapper">
      <div className="container full-height justify-between column">
        <div className={`videoOverlay ${!isEnd ? "" : "hidden"}`}></div>
        <div className="page-header">
          <Header showLogo={showLogo} />
        </div>
        <div className="page-content">
          <div className="container justify-center" ref={myRef}>
            {isEnd ? <VideoSelectorHorizontal finale={isFinale} /> : null}

            {isEnd ? null : (
              <div className="box removepadding col-12 visible ">
                <div className="videoContainer">
                  {isReveal || isFinale ? null : (
                    <button
                      className="buttonCloseVideo"
                      onClick={() => setIsEnd(true)}
                    >
                      <img src={close_b} alt="X" />
                    </button>
                  )}
                  <VideoPlayer
                    action={handleVideoAction}
                    name={videoName}
                    lang={t("lngcode")}
                  />
                  {showRotate && isReveal ? (
                    <div className="box col-12 rotate-message center">
                      {t("rotate-message")}
                    </div>
                  ) : null}
                </div>
              </div>
            )}
            {isEnd ? <VideoNavigator finale={isFinale} /> : null}
          </div>
        </div>
        <div className="page-footer">
          {isEnd ? (
            <div className="container justify-left restartContainer">
              <div className="box">
                <Link to="/" className="button ghost left">
                  <div className="info">
                    &nbsp;&nbsp;{t("button-watch-again")}
                  </div>
                  <div className="icon">
                    <img src={restart} alt="Viewed" />
                  </div>
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VideoPage;
